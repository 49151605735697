.about-header {
  min-height: 25vh;
  text-align: center;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(/assets/yard.jpeg) */
}

.about {
  margin-top: 1em;
  background-color: "#f8f8f8";
  width: 100%;
}

.about-header-text {
  left: 0;
  position: absolute;
  text-align: center;
  top: 11%;
  width: 100%;
  color: white;
  z-index: 2;
}

/* .about-splash {
    height: 30vh;
    width: 100vw;
    object-fit: cover;
    background-attachment: fixed;
  }
  
  .about-overlay {
    height: 30vh;
    width: 100vw;
    padding: 0;
    position: absolute;
    background-color: black;
    opacity: 0.30;
    z-index: 1;
  } */

.about-container {
  justify-content: center;
  align-items: center;
  padding: 2% 15%;
}

.about-profile {
  padding-top: 5%;
  display: flex;
  width: 75%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.about-text {
  text-align: center;
}

.about-dp-title {
  margin: 1em;
}

.about-dp {
  max-height: 20vh;
}

.about-values {
  padding: 4% 20% 6% 20%;
}

.about-profile-text {
  font-size: 1em;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .about-header {
    min-height: 20vh;
  }

  .about-header-text {
    top: 10%;
  }

  .about-profile {
    flex-direction: column;
    width: 100%;
  }

  .about {
    margin-top: 2em;
  }

  .about-values {
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .body-section {
    padding-top: 5%;
    padding-bottom: 5%;
  }
}
