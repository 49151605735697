h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 2.9em;
  letter-spacing: -0.025em;
  text-align: left;
}

nav {
  margin: 0;
}

h2 {
  font-style: normal;
  font-size: 1.8em;
  margin: 0 0 0.5em 0;
}

h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.3em;
  margin: 0;
  text-align: center;
}

h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.1em;
  margin: 0;
}

h5 {
  margin: 0;
  font-weight: 400;
  font-size: 1em;
}

.header-text {
  text-align: "left";
}

.body-section {
  padding: 0% 8% 0% 8%;
  margin: 4% 0 2% 0;
}

.hero {
  min-height: 70vh;
  text-align: left;
}

.hero-video {
  height: 70vh;
  width: 100vw;
  position: absolute;
  object-fit: cover;
}

.hero-overlay {
  height: 70vh;
  width: 100vw;
  padding: 0;
  position: absolute;
  background-color: black;
  opacity: 0.35;
  z-index: 1;
}

.hero-text {
  z-index: 2;
  position: absolute;
  width: 30%;
  left: 15%;
  top: 13%;
  color: white;
}

.card-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-flow: row wrap;
  flex-direction: row;
  margin-top: 4%;
}

.services-card-block {
  width: 20vw;
  height: 35vh;
  margin: 0 2% 4% 2%;
  background: white;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
  border-radius: 5px;
  font-size: 0.9em;
}

.services-card-block > img {
  width: 100%;
  height: 45%;
}

.services {
  padding-left: 0;
  padding-right: 0;
}

.services-img {
  object-fit: cover;
  flex: 1;
}

.services-content {
  flex: 1;
  margin: 0 1em 1em 1em;
}

.services-title {
  margin: 0.5em;
}

.services-text {
  font-size: 0.9em;
  text-align: left;
}

.points-card-block {
  display: block;
  width: 20vw;
  height: 25vh;
  margin: 0 1% 4% 1%;
  background: none;
  font-size: 1em;
}

.points-card-block > img {
  width: 30%;
  object-fit: cover;
}

.points-text {
  font-size: 0.9em;
  text-align: center;
}

.portfolio-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4% 10% 0% 10%;
}

.portfolio-img {
  flex: 1;
  height: 30vh;
  object-fit: cover;
}

.portfolio-text {
  flex: 1;
  padding: 2%;
  font-size: 0.95em;
}

.contact {
  background-color: #fbfcfd;
}

.contact-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.firm-container {
  display: inline-block;
  width: 100%;
  padding: 3% 0 0 3%;
}

.firm-logo {
  height: 8vw;
  padding-right: 5%;
}

.testimonial {
  padding: 4% 0;
}

.contact-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 4% 0 0 4%;
  text-align: left;
}

.form {
  background-color: transparent;
  color: black;
  width: 90%;
}

.form-text {
  padding: 0.5em 0 0.5em 0;
  color: #000000;
  font-size: 0.9em;
  font-weight: 600;
  float: left;
}

.form-input {
  display: block;
  width: 100%;
  padding: 0.75em;
  margin-bottom: 1em;
  font-size: 0.7em;
  line-height: 1.42857143;
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  line-height: normal;
}

.submit-button {
  margin-top: 1em;
  padding: 1em 30% 1em 30%;
  font-size: 0.8em;
  border-radius: 5px;
  border: 0;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.submit-button:hover {
  background-color: rgb(223, 223, 223);
}

.contact-block {
  width: 30%;
  margin-right: 10%;
}

.contact-block > h4 {
  text-align: center;
}

.contact-text {
  font-size: 0.9em;
}

.footer {
  padding: 5% 2%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer-div {
  flex: 1;
}

.footer-strong {
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: 1em;
}

.footer-text {
  font-size: 1em;
}

.footer-div-img {
  margin-top: 0.5em;
}

.sticky {
  position: fixed;
}

.nav {
  top: 0;
  left: 0;
  height: 2vh;
  max-height: 10px;
  width: 97vw;
  padding: 1.5vw 2vw 1.5vw 2vw;
  z-index: 3;

  background-color: rgba(25, 25, 25, 0);

  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}

.scrolled {
  visibility: hidden;
}

.nav-logo {
  margin-right: auto;
  margin-left: 1.5vw;
  height: 250%;
}

.nav-link {
  padding: 1.5vw;
  margin-top: 1em;
  cursor: pointer;
  text-decoration: none;
}

.nav-text {
  font-weight: 600;
  font-size: 1.1em;
}

.nav-title {
  font-size: 1.1em;
}

/* smaller screens */
@media only screen and (max-width: 768px) {
  html {
    font-size: 80%;
  }

  body {
    text-align: center;
  }

  .body-section {
    padding: 0 2% 0 2%;
  }

  .nav {
    margin-top: 5%;
    background-color: "#f8f8f8";
  }

  .card-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer {
    padding: 5% 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-div {
    padding: 5% 0%;
  }

  .services-card-block {
    width: 80vw;
    height: auto;
    margin: 5%;
  }

  .services-text {
    text-align: center;
  }

  .hero-text {
    width: 75%;
    top: 15%;
  }

  .points-card-block {
    width: 80vw;
    height: auto;
    margin: 4%;
  }

  .portfolio-block {
    flex-direction: column;
    padding: 4% 0;
  }

  .portfolio-text {
    padding: 2% 0 2% 0;
  }

  .firm-logo {
    height: 25vw;
  }

  .contact-container {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  .contact-block {
    width: 98%;
    margin: 5% 10px 5% 10px;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }
}

ul {
  margin: 0;
  padding-left: 1.75em;
}

/* even smaller screens */
@media only screen and (max-width: 400px) {
  html {
    font-size: 70%;
  }
}
