.App {
  text-align: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(8px + 1.05vmin);
  color: black;
  justify-content: center;
  overflow: hidden;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Source Sans Pro", "Inter", sans-serif;
  font-weight: 400;
}

input,
textarea {
  font-family: "Inter";
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none !important;
}
